<template>
  <div
    class="card-container"
    v-if="
      withPermissionName(permissions.TRA_CON_REP_03) ||
        withPermissionName(permissions.TRA_CON_REP_03)
    "
  >
    <h5>Reporte por Contrato</h5>
    <div class="card-body">
      <div class="row">
        <div
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
          @click="openDialogReport()"
        >
          <div class=" card card-wrapper excel ">
            <i class="fa fa-file-excel-o fa-2x"></i>
            <div class="card-body">
              <h4>Reportes por Contrato</h4>
              <p>Concentrado de reporte por contrato</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogReport" width="650">
        <v-card>
          <v-card-title class="text-h5 lighten-1">
            Reportes por contrato
          </v-card-title>
          <v-container>
            <div class="col-md-12 pb-0">
              <a v-b-toggle.collapse-4
                ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>
                Existencias por Almacén</a
              >
              <b-collapse id="collapse-4" class="mt-2">
                <b-list-group>
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/30Ene2021_ExistenciasFMedical.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >30Ene2021_ExistenciasFMedical.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/26Feb2021_ExistenciasFMedical.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >26Feb2021_ExistenciasFMedical.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/29Mar2021_ExistenciasFMedical.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >29Mar2021_ExistenciasFMedical.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/29Abr2021_movimientoInventarioExistencias_I y II.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >29Abr2021_movimientoInventarioExistencias_I y
                    II.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/31May2021_movimientoInventarioExistencias_I y II.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >31May2021_movimientoInventarioExistencias_I y
                    II.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/30Jun2021_movimientoInventarioExistencias_I y II.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >30Jun2021_movimientoInventarioExistencias_I y
                    II.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/30Jul2021_movimientoInventarioExistencias_I y II.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >30Jul2021_movimientoInventarioExistencias_I y
                    II.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/31Ago2021_movimientoInventarioExistencias_I y II.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >31Ago2021_movimientoInventarioExistencias_I y
                    II.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/30Sept2021_movimientoInventarioExistencias_I y II.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >30Sept2021_movimientoInventarioExistencias_I y
                    II.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Existencias por Almacen/31Dic2021_ExistenciasFMedical.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon"
                      aria-hidden="true"
                    ></i
                    >31Dic2021_ExistenciasFMedical.xlsx</b-list-group-item
                  >
                </b-list-group>
              </b-collapse>
              <hr />
            </div>

            <div class="col-md-12 pb-0 pt-0">
              <a v-b-toggle.collapse-5
                ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>Recetas
                porcentaje</a
              >
              <b-collapse id="collapse-5" class="mt-2">
                <b-list-group>
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Enero.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Enero.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Febrero.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Febrero.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Marzo.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Marzo.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Abril.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Abril.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Mayo.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Mayo.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Junio.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Junio.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Julio.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Julio.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Agosto.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Agosto.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Septiembre.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Septiembre.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Octubre.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Octubre.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument(
                        'Recetas porcentaje/Recetas porcentaje Diciembre.xlsx'
                      )
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Recetas porcentaje Diciembre.xlsx</b-list-group-item
                  >
                </b-list-group>
              </b-collapse>
              <hr />
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <a v-b-toggle.collapse-6
                ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>Reporte
                de Caducos</a
              >
              <b-collapse id="collapse-6" class="mt-2">
                <b-list-group>
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Enero.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Enero.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Febrero.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Febrero.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Marzo.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Marzo.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Abril.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Abril.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Mayo.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Mayo.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Junio.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Junio.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Julio.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Julio.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Agosto.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Agosto.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="
                      downloadDocument('Reporte de Caducos/Septiembre.xlsx')
                    "
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Septiembre.xlsx</b-list-group-item
                  >
                  <b-list-group-item
                    button
                    @click="downloadDocument('Reporte de Caducos/Octubre.xlsx')"
                    ><i
                      class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                      aria-hidden="true"
                    ></i
                    >Octubre.xlsx</b-list-group-item
                  >
                </b-list-group>
              </b-collapse>
              <hr />
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <a v-b-toggle.collapse-1
                ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>Entradas
                por Proveedor</a
              >
              <b-collapse id="collapse-1" class="mt-2">
                <div class="col-md-12">
                  <a v-b-toggle.collapse-2
                    ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>CEDIS
                    Metropolitano 1</a
                  >
                  <b-collapse id="collapse-2" class="mt-2">
                    <b-list-group>
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Enero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Enero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Febrero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Febrero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Marzo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Marzo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Abril.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Abril.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Mayo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Mayo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Junio.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Junio.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1SCALD_Entradas por Proveedor - Julio.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Julio.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Agosto.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Agosto.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Septiembre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Septiembre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Octubre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Octubre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Noviembre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Noviembre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 1/SCALD_Entradas por Proveedor - Diciembre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Diciembre.xlsx</b-list-group-item
                      >
                    </b-list-group>
                  </b-collapse>
                </div>
                <div class="col-md-12">
                  <a v-b-toggle.collapse-3
                    ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>CEDIS
                    Metropolitano 2</a
                  >
                  <b-collapse id="collapse-3" class="mt-2">
                    <b-list-group>
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Enero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Enero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Febrero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Febrero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Marzo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Marzo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Abril.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Abril.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Mayo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Mayo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Julio.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Julio.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Septiembre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Septiembre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Octubre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Octubre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Entradas por Proveedor/CEDIS Metropolitano 2/SCALD_Entradas por Proveedor - Noviembre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >SCALD_Entradas por Proveedor -
                        Noviembre.xlsx</b-list-group-item
                      >
                    </b-list-group>
                  </b-collapse>
                </div>
              </b-collapse>
              <hr />
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <a v-b-toggle.collapse-7
                ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>Salidas
                de CEDIS</a
              >
              <b-collapse id="collapse-7" class="mt-2">
                <div class="col-md-12">
                  <a v-b-toggle.collapse-9
                    ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>CEDIS
                    Metropolitano 1</a
                  >
                  <b-collapse id="collapse-9" class="mt-2">
                    <b-list-group>
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Enero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Enero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Febrero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Febrero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Marzo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Marzo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Abril.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Abril.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Mayo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Mayo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Junio.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Junio.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Julio.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Julio.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Agosto.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Agosto.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Septiembre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Septiembre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 604 Octubre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 604 Octubre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 1/Salidas CEDIS 693 Octubre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 693 Octubre.xlsx</b-list-group-item
                      >
                    </b-list-group>
                  </b-collapse>
                </div>
                <div class="col-md-12 pb-0">
                  <a v-b-toggle.collapse-8
                    ><i class="fa fa-folder-o mr-2" aria-hidden="true"></i>CEDIS
                    Metropolitano 2</a
                  >
                  <b-collapse id="collapse-8" class="mt-2">
                    <b-list-group>
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Enero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Enero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Febrero.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Febrero.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Marzo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Marzo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Abril.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Abril.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Mayo.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Mayo.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Junio.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Junio.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Julio.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Julio.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Agosto.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Agosto.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Septiembre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Septiembre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 605 Octubre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 605 Octubre.xlsx</b-list-group-item
                      >
                      <b-list-group-item
                        button
                        @click="
                          downloadDocument(
                            'Salidas de CEDIS/CEDIS Metropolitano 2/Salidas CEDIS 694 Octubre.xlsx'
                          )
                        "
                        ><i
                          class="fa fa-file-excel-o mr-2 excel-icon excel-icon"
                          aria-hidden="true"
                        ></i
                        >Salidas CEDIS 694 Octubre.xlsx</b-list-group-item
                      >
                    </b-list-group>
                  </b-collapse>
                </div>
              </b-collapse>
              <hr />
            </div>
            <div class="col-md-12 text-right">
              <button
                type="button"
                class="btn btn-outline-danger ml-3"
                @click="dialogReport = false"
              >
                <i class=" feather icon-x mr-1"></i> Cancelar
              </button>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";

export default {
  data() {
    return {
      permissions: PermissionConstants,
      dialogReport: false,
    };
  },
  methods: {
    openDialogReport() {
      this.dialogReport = true;
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    downloadDocument(url) {
      window.open(`/files/${url}`, "_blank");
    },
  },
};
</script>


<style scoped>
.theme--light.v-messages {
  color: rgb(255 0 0);
}

/*Código para modificar los botones */
@media (max-width: 576px) {
  .controlSize {
    text-align: center;
  }

  /*Código para modificar las barras de fecha o botones de busqueda*/
  .selectSize {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .controlSize {
    float: right;
  }
}

.list-group-item {
  border: 0px solid !important;
  padding: 0.5rem 2rem !important;
}

.excel-icon {
  color: #429559 !important;
}
</style>