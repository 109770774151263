<template>
  <v-autocomplete
    v-if="suppliersList"
    preset-val="1"
    :error-messages="errors"
    :disabled="isDisabled"
    v-model="idSupplier"
    :items="suppliersList"
    @change="suppliersChange"
    :label="suppliersLabel"
  />
</template>

<script>
import { SupplierRequest } from "@/core/request";

export default {
  props: {
    suppliersLabel: "",
    errors: "",
  },
  data() {
    return {
      idSupplier: 1,
      suppliersList: [],
      isDisabled: false,
    };
  },
  mounted() {},
  methods: {
    suppliersChange(data) {
      this.$emit("suppliersChange", this.idSupplier);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponent(id) {
      for (var item in this.suppliersList) {
        if (item == id) {
          this.idSupplier = item;
        }
      }
    },
    listAllSuppliers() {
      SupplierRequest.listAll().then((response) => {
        let { data } = response.data;
        this.suppliersList = data.objects.map((obj) => {
          return {
            value: { idSupplier: obj.idSupplier },
            text: `${obj.name} - ${obj.supplierKey}`,
          };
        });
      });
    },
  },

  created() {
    this.listAllSuppliers();
  },
};
</script>
