<template>
  <div>
    <!-- Almacén -->
    <div
      class="card-container"
      v-if="
        withPermissionName(permissions.TRA_WAR_INV_06) ||
        withPermissionName(permissions.TRA_REPORT_OPD_16) ||
        withPermissionName(permissions.TRA_REPORT_OPD_17) ||
        withPermissionName(permissions.TRA_REPORT_OPD_18) ||
        withPermissionName(permissions.TRA_REPORT_OPD_19)
      "
    >
      <h5>OPD</h5>
      <div class="card-body">
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
            @click="inventory_cedis_opd"
            v-if="withPermissionName(permissions.TRA_WAR_INV_06)"
          >
            <div class="card card-wrapper excel">
              <i class="fa fa-file-excel-o fa-2x"></i>
              <div class="card-body">
                <h4>
                  {{ $t("traceability.warehouse_invetory") }}
                </h4>
                <p>
                  {{ $t("traceability.warehouse_invetory_text") }}
                </p>
              </div>
            </div>
          </div>
         <!--  <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
            @click="inventory_pharmacy_opd"
            v-if="withPermissionName(permissions.TRA_REPORT_OPD_16)"
          >
            <div class="card card-wrapper excel">
              <i class="fa fa-file-excel-o fa-2x"></i>
              <div class="card-body">
                <h4>Inventario en Farmacias</h4>
                <p>Concentrado de inventario en farmacias</p>
              </div>
            </div>
          </div> -->

          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
            v-if="withPermissionName(permissions.TRA_REPORT_OPD_17)"
            @click="changeLevel(2)"
          >
            <div class="card card-wrapper excel">
              <i class="fa fa-file-excel-o fa-2x"></i>
              <div class="card-body">
                <h4>Entradas por Proveedores</h4>
                <p>
                  Concentrado de entradas por proveedores a cedis en los
                  diferentes proveedores por fecha.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
            v-if="withPermissionName(permissions.TRA_REPORT_OPD_18)"
            @click="changeLevel(3)"
          >
            <div class="card card-wrapper excel">
              <i class="fa fa-file-excel-o fa-2x"></i>
              <div class="card-body">
                <h4>Entradas por Traspaso</h4>
                <p>
                  Concentrado de entradas por traspaso a cedis en los diferentes
                  proveedores por fecha.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5"
            v-if="withPermissionName(permissions.TRA_REPORT_OPD_19)"
            @click="changeLevelOriginDestinyOPD(1)"
          >
            <div class="card card-wrapper excel">
              <i class="fa fa-file-excel-o fa-2x"></i>
              <div class="card-body">
                <h4>Ordenes de Salidas</h4>
                <p>
                  Concentrado de ordenes de salida, filtrado por fecha y
                  sucursales de destino.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-------------------------------------------------------- Salidas --------------------------------------------------------->
    <v-dialog v-model="dialogOriginDestinyOPD" max-width="550" persistent>
      <v-card>
        <v-card-title class="headline">
          <h4>Ordenes de Salidas</h4>
        </v-card-title>
        <v-container>
          <validation-observer>
            <div class="col-md-12 pb-12">
              <form @submit.prevent="outgoing_opd">
                <div class="row align-center">
                  <div class="col-md-12">
                    <div class="row text-center">
                      <div
                        :class="
                          levelOriginDestinyOPD === 1 ? 'col-md-9' : 'col-md-11'
                        "
                      >
                        <AllBranchOfficeComponent
                          ref="allBranchOfficeDestiny"
                          :branchLabel="$t('label.branch_destination')"
                          v-on:branchChange="branchChangeOrigin"
                        />
                      </div>

                      <div class="col-md-2" v-if="levelOriginDestinyOPD === 1">
                        <v-switch
                          v-model="isAllOPD"
                          inset
                          @change="allBranchOfficeByDestiny()"
                          class="pl-5 pt-2"
                          :label="$t('label.all')"
                        ></v-switch>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div
                      class="text-center"
                      v-if="
                        levelOriginDestinyOPD === 1 ||
                        levelOriginDestinyOPD === 3
                      "
                    >
                      <DateByRangeComponent
                        :typeCalendar="1"
                        v-on:dateChange="dateChange"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div
                      class="text-center pb-10"
                      v-if="levelOriginDestinyOPD === 1"
                    >
                      <v-autocomplete
                        :label="$t('label.output_types')"
                        :items="typeOrderList"
                        required
                        clearable
                        v-model="typeOrderOpd"
                      />
                    </div>
                  </div>
                  <div class="col-md-12 text-center pb-10">
                    <button class="btn btn-outline-success mr-2" type="submit">
                      <i class="feather icon-download mr-1"></i>
                      {{ $t("global.generate") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-danger"
                      @click="dialogOriginDestinyOPD = false"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </validation-observer>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDateRange" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline">
          <h4>{{ $t(modalDateRangeTitleOPD()) }}</h4>
        </v-card-title>
        <v-container class="pt-0">
          <validation-observer ref="observer3">
            <form @submit.prevent="traceabilityRemmisionSupplier()">
              <div class="col-md-12 text-center">
                <DateByRangeComponent
                  ref="DateByRangeComponent"
                  :typeCalendar="3"
                  v-on:dateChange="dateChange"
                />
              </div>
              <div class="col-md-12 text-center">
                <button class="btn btn-outline-success mr-4 mt-4" type="submit">
                  <i class="feather icon-download mr-1"></i>
                  {{ $t("global.generate") }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger mt-4"
                  @click="clearDateAndCloseDialog(1)"
                >
                  {{ $t("global.cancel") }}
                </button>
              </div>
            </form>
          </validation-observer>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import DateByRangeComponent from "@/common/calendar/DateByRangeComponent.vue";
import { ExcelRequest } from "@/core/request";
import AllBranchOfficeComponent from "@/common/select/AllBranchOfficeComponent.vue";

export default {
  props: {
    branchOffice: {},
    typeOrderList: [],
  },
  data() {
    return {
      permissions: PermissionConstants,
      dialogReport: false,
      dialogDateRange: false,
      levelCalendar: 0,
      dateModelMov: [],
      dialogOriginDestinyOPD: 0,
      levelOriginDestinyOPD: 0,
      idBranchOfficeOriginOpd: 0,
      isAllOPD: false,
      typeOrderOpd: "",
    };
  },
  methods: {
    /* Modal Rango de Fecha */
    modalDateRangeTitleOPD() {
      const titleMap = {
        2: "Entradas por Proovedores",
        3: "Entradas por Traspaso",
        4: "Entradas por trapaso",
      };
      return titleMap[this.levelCalendar] || "traceability.not_defined";
    },
    allBranchOfficeByDestiny() {
      this.$refs.allBranchOfficeDestiny.reloadComponentDisabled(this.isAllOPD);
    },
    changeLevel(level) {
      this.dialogDateRange = true;
      this.levelCalendar = level;
    },
    outgoing_opd() {
      if (this.idBranchOfficeOriginOpd || this.isAllOPD || this.typeOrderOpd) {
        //Muestro la pantalla de carga
        this.loading = UtilFront.getLoadding();
        ExcelRequest.outgoing_opd({
          idBranchOfficeDestiny: this.idBranchOfficeOriginOpd,
          isAll: this.isAllOPD,
          startEndDate: this.dateShipment,
          type: this.typeOrderOpd,
        })
          .then((response) => {
            ExcelRequest.download(response, "ordenes_de_salida.xlsx");
            this.$toast.success("Reporte generado (documento.xlsx)");
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {
            this.loading.hide();
          });
        this.dialogMovEx = false;
        //this.clearCalendarMov();
      }
    },
    branchChangeOrigin(item) {
      this.idBranchOfficeOriginOpd = item.idBranchOffice;
    },
    openDialogReport() {
      this.dialogReport = true;
      4;
    },
    changeLevelOriginDestinyOPD(level) {
      this.dialogOriginDestinyOPD = true;
      this.levelOriginDestinyOPD = level;
    },
    dateChange(dateShipment) {
      this.dateShipment = dateShipment;
      this.dateModelMov = dateShipment;
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    traceabilityRemmisionSupplier() {
      // Verify that the date range is present
      if (
        this.dateModelMov.startDate !== "" &&
        this.dateModelMov.endDate !== ""
      ) {
        const requestData = {
          userBranchOffice: {
            idBranchOffice: this.branchOffice.idBranchoffice,
            idUser: this.$UtilFront.getDataUser().idUser,
          },
          startEndDate: {
            startDate: this.dateModelMov.startDate,
            endDate: this.dateModelMov.endDate,
          },
        };

        const requestPromise =
          this.levelCalendar === 2
            ? ExcelRequest.supplier_entries_opd(requestData)
            : ExcelRequest.transfer_entries_opd(requestData);

        this.loading = UtilFront.getLoadding();
        requestPromise
          .then((response) => {
            const { success, message, data } = response.data.data;
            if (success) {
              ExcelRequest.downloadBase64(data, message);
              this.$toast.success("Reporte generado (documento.xlsx)");
            } else {
              this.$toast.error(message);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading.hide();
            this.dialogMovEx = false;
          });
      }
    },
    /* Global */
    clearDateAndCloseDialog(dialog) {
      this.dateModelMov.startDate = "";
      this.dateModelMov.endDate = "";
      this.$refs.DateByRangeComponent.modalDateRangeClean();
      if (dialog == 1) {
        this.dialogDateRange = false;
      } else if (dialog == 2) {
        this.medicalPrescriptionDialog = false;
      } else if (dialog == 3) {
        this.medicalPrescriptionIntrahospitalDialog = false;
      }
    },
    inventory_pharmacy_opd() {
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();
      ExcelRequest.inventory_pharmacy_opd({
        userBranchOffice: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: this.$UtilFront.getDataUser().idUser,
        },
      })
        .then((response) => {
          ExcelRequest.download(response, "inventario_en_farmacias.xlsx");
          this.$toast.success("Reporte generado (inventario_en_farmacias.xlsx)");
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    inventory_cedis_opd() {
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();
      ExcelRequest.inventory_cedis_opd({
        idBranchOffice: this.branchOffice.idBranchoffice,
        startDate: 0,
        endDate: 0,
      })
        .then((response) => {
          ExcelRequest.download(response, "inventario_en_cedis.xlsx");
          this.$toast.success("Reporte generado (inventario_en_cedis.xlsx)");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
  },
  components: {
    DateByRangeComponent,
    AllBranchOfficeComponent,
  },
};
</script>
