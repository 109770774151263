<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-8 col-lg-8">
            <h4 class="page-title">{{ $t("traceability.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard"> {{ $t("traceability.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }} </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- End Breadcrumbbar -->
      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <template>
              <v-card style="box-shadow: none">
                <v-card-title>
                  <h5 class="card-title mb-0">
                    <i class="feather icon-folder" aria-hidden="true"></i>
                    {{ $t(titleAction) }}
                  </h5>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <!-- Kardex -->

                  <div
                    class="card-container"
                    v-if="
                      withPermissionName(permissions.TRA_PRO_KAR_01) ||
                        withPermissionName(permissions.TRA_BRA_INV_02)
                    "
                  >
                    <h5>{{ $t("traceability.kardex") }}</h5>
                    <div class="card-body">
                      <div class="row">
                        <div
                          v-if="withPermissionName(permissions.TRA_PRO_KAR_01)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="dialog = true"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.kardex_product") }}</h4>
                              <p>
                                {{ $t("traceability.kardex_product_text") }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="withPermissionName(permissions.TRA_BRA_INV_02)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="openDialog"
                        >
                          <div class="card card-wrapper pdf">
                            <i class="fa fa-file-pdf-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.branch_stocks") }}</h4>
                              <p>{{ $t("traceability.branch_stocks_text") }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Kardex -->
                  <ReportContract />

                  <!-- Entradas -->
                  <div
                    class="card-container"
                    v-if="
                      withPermissionName(permissions.TRA_REM_SUP_04) ||
                        withPermissionName(permissions.TRA_REM_COS_05) ||
                        withPermissionName(permissions.TRA_REM_SUP_04)
                    "
                  >
                    <h5>{{ $t("traceability.entries") }}</h5>
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          v-if="withPermissionName(permissions.TRA_REM_SUP_04)"
                          @click="changeLevel(2)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.entries_supplier") }}</h4>
                              <p>
                                {{ $t("traceability.entries_supplier_text") }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          v-if="withPermissionName(permissions.TRA_REM_SUP_04)"
                          @click="changeLevel(4)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>Entradas por Traspaso</h4>
                              <p>
                                Concentrado de entradas por proveedor a cedis de
                                los diferentes proveedores por fecha
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          v-if="withPermissionName(permissions.TRA_REM_COS_05)"
                          @click="changeLevel(3)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.entries_cost") }}</h4>
                              <p>{{ $t("traceability.entries_cost_text") }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Entradas -->
                  <div
                    class="card-container"
                    v-if="
                      withPermissionName(permissions.REPORT_ENTRIES_OUTPUTS)
                    "
                  >
                    <h5>Administración</h5>
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="dialogReportEntries = true"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>Reporte de Entradas y Salidas</h4>
                              <p>
                                Concentrado de Reporte de Entradas y Salidas.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Almacén -->
                  <div
                    class="card-container"
                    v-if="
                      withPermissionName(permissions.TRA_CYC_INV_07) ||
                        withPermissionName(permissions.TRA_EXP_08)
                    "
                  >
                    <h5>{{ $t("traceability.warehouse") }}</h5>
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="dialogStockObservation = true"
                          v-if="withPermissionName(permissions.TRA_CYC_INV_07)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>
                                {{ $t("traceability.cyclical_inventory") }}
                              </h4>
                              <p>
                                {{ $t("traceability.cyclical_inventory_text") }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="traceabilityExpiredStock"
                          v-if="withPermissionName(permissions.TRA_EXP_08)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.expired") }}</h4>
                              <p>{{ $t("traceability.expired_text") }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Salidas -->
                  <div
                    class="card-container"
                    v-if="withPermissionName(permissions.TRA_STA_ORD_09)"
                  >
                    <h5>{{ $t("traceability.outputs_orders") }}</h5>
                    <div class="card-body">
                      <div class="row">
                        <div
                          v-if="withPermissionName(permissions.TRA_STA_ORD_09)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="changeLevelOriginDestiny(1)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.outputs_orders") }}</h4>
                              <p>
                                {{ $t("traceability.outputs_orders_text") }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Recepción -->
                  <div
                    class="card-container"
                    v-if="withPermissionName(permissions.TRA_REC_100)"
                  >
                    <h5>{{ $t("traceability.reception") }}</h5>
                    <div class="card-body">
                      <div class="row">
                        <div
                          v-if="withPermissionName(permissions.TRA_REC_10)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="changeLevelOriginDestiny(2)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.reception") }}</h4>
                              <p>{{ $t("traceability.reception_text") }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Dispensacion -->
                  <div
                    class="card-container"
                    v-if="
                      withPermissionName(permissions.TRA_PER_DIS_12) ||
                        withPermissionName(permissions.TRA_DIS_INT_14) ||
                        withPermissionName(permissions.TRA_DIS_INT_15) ||
                        withPermissionName(permissions.TRA_DIS_INT_13)
                    "
                  >
                    <h5>{{ $t("traceability.dispensing") }}</h5>
                    <div class="card-body">
                      <div class="row">
                        <!--   <div
                          v-if="withPermissionName(permissions.TRA_DIS_11)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="openDialogMedicalPrescription(1)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>{{ $t("traceability.dispensing") }}</h4>
                              <p>{{ $t("traceability.dispensing_text") }}</p>
                            </div>
                          </div>
                        </div> -->

                        <div
                          v-if="withPermissionName(permissions.TRA_DIS_INT_14)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                          @click="downloadDispensingConsolidated(true)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>
                                {{ $t("traceability.dispensing_consolidated") }}
                              </h4>
                              <p>
                                {{
                                  $t(
                                    "traceability.dispensing_consolidated_text"
                                  )
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="withPermissionName(permissions.TRA_DIS_INT_15)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                          @click="downloadDispensingConsolidated(false)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>
                                {{
                                  $t(
                                    "traceability.dispensing_consolidated_denied"
                                  )
                                }}
                              </h4>
                              <p>
                                {{
                                  $t(
                                    "traceability.dispensing_consolidated_text_denied"
                                  )
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="withPermissionName(permissions.TRA_DIS_INT_13)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                          @click="openDialogMedicalIntrahospitalPrescription(1)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>Dispensación Intrahospitalaria</h4>
                              <p>
                                Concentrado de recetas intrahospitalarias
                                filtradas por fecha.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="withPermissionName(permissions.TRA_PER_DIS_12)"
                          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          @click="openDialogMedicalPrescription(1)"
                        >
                          <div class="card card-wrapper excel">
                            <i class="fa fa-file-excel-o fa-2x"></i>
                            <div class="card-body">
                              <h4>
                                {{ $t("traceability.percentage_dispensing") }}
                              </h4>
                              <p>
                                {{
                                  $t("traceability.percentage_dispensing_text")
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- OPD -->
                  <ReportOpd
                    :branchOffice="branchOfficeState"
                    :typeOrderList="typeOrderList"
                  />
                  <!-- Dispensacion -->
                </v-card-text>
              </v-card>
            </template>
          </div>
        </div>
      </div>
      <!-- End Contentbar -->
      <v-dialog v-model="dialog" persistent max-width="1090">
        <v-card>
          <v-card-title class="headline">
            <h4>{{ $t("traceability.kardex_by_product") }}</h4>
          </v-card-title>
          <v-card-text class="pt-5">
            <v-autocomplete
              auto-select-first
              outlined
              class="br"
              :items="products"
              :no-data-text="$t('global.no_result')"
              v-model="idProduct"
              clearable
              :label="$t('label.products')"
            >
              <template slot="item" slot-scope="data">
                <div class="pt-5">
                  {{ data.item.text }}
                </div>
              </template>

              <template slot="selection" slot-scope="data">
                <div class="pt-5">
                  {{ data.item.text }}
                </div>
              </template>
            </v-autocomplete>
          </v-card-text>

          <v-card-actions class="p-2 pb-8">
            <v-spacer></v-spacer>
            <button
              class="btn btn-outline-success mr-4"
              @click="traceabilityKardex"
              :disabled="sending"
            >
              <i class="feather icon-download mr-1"></i>
              {{
                sending ? $t("global.downloading_to") : $t("global.downloading")
              }}
            </button>
            <button
              type="button"
              class="btn btn-outline-danger mr-4"
              @click="dialog = false"
              :disabled="sending"
            >
              <i class="feather icon-x mr-1"></i>
              {{ $t("global.cancel") }}
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--------------------------------------------------------- Kardex --------------------------------------------------------->

      <!------------------------------------------------- Productos en Existencia ------------------------------------------------>
      <v-dialog v-model="dialogExistence" persistent width="80%">
        <v-card>
          <v-card-title class="headline">
            <h4>{{ $t("traceability.existence_in_branches") }}</h4>
          </v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12 col-lg-12 pt-9">
                <v-autocomplete
                  :items="products"
                  outlined
                  class="br"
                  v-model="idsProduct"
                  :no-data-text="$t('global.no_result')"
                  :label="$t('label.products')"
                  @change="listAllLot(idsProduct)"
                >
                  <template slot="item" slot-scope="data">
                    <div class="pt-5">
                      {{ data.item.text }}
                    </div>
                  </template>

                  <template slot="selection" slot-scope="data">
                    <div class="pt-5">
                      {{ data.item.text }}
                    </div>
                  </template>
                </v-autocomplete>
              </div>
              <div class="col-md-12 col-lg-12">
                <v-autocomplete
                  multiple
                  :items="lots"
                  outlined
                  class="br"
                  v-model="lotsList"
                  :no-data-text="$t('global.no_result')"
                  chips
                  :label="$t('label.lotes')"
                  clearable
                >
                  <template #selection="{ item, index }">
                    <v-chip
                      color="success"
                      close
                      @click:close="lotsList.splice(index, 1)"
                    >
                      {{ item.text }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <button
              class="btn btn-outline-success mr-4"
              @click="downloadPDFExistence"
              :disabled="sending"
            >
              <i class="feather icon-download mr-1"></i>
              {{
                sending ? $t("global.downloading_to") : $t("global.downloading")
              }}
            </button>
            <button
              type="button"
              class="btn btn-outline-danger mr-4"
              @click="dialogExistence = false"
              :disabled="sending"
            >
              <i class="feather icon-x mr-1"></i> {{ $t("global.cancel") }}
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!------------------------------------------------- Productos en Existencia ------------------------------------------------>

      <!-------------------------------------------------------- Entradas -------------------------------------------------------->
      <v-dialog v-model="dialogDateRange" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">
            <h4>{{ $t(modalDateRangeTitle) }}</h4>
          </v-card-title>
          <v-container class="pt-0">
            <validation-observer ref="observer3">
              <form
                @submit.prevent="
                  levelCalendar == 3
                    ? excelCostsPerWarehouse()
                    : traceabilityRemmisionSupplier()
                "
              >
                <div class="col-md-12 text-center">
                  <DateByRangeComponent
                    ref="DateByRangeComponent"
                    :typeCalendar="3"
                    v-on:dateChange="dateChange"
                  />
                </div>
                <div class="col-md-12 text-center">
                  <button
                    class="btn btn-outline-success mr-4 mt-4"
                    type="submit"
                  >
                    <i class="feather icon-download mr-1"></i>
                    {{ $t("global.generate") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger mt-4"
                    @click="clearDateAndCloseDialog(1)"
                  >
                    {{ $t("global.cancel") }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </v-container>
        </v-card>
      </v-dialog>
      <!-------------------------------------------------------- Entradas -------------------------------------------------------->

      <v-dialog
        v-model="medicalPrescriptionIntrahospitalDialog"
        max-width="400"
        persistent
      >
        <v-card>
          <v-card-title class="headline">
            <h4>
              {{ $t("traceability.dispensing") }}
            </h4>
          </v-card-title>
          <v-container class="pt-0">
            <!--<v-switch  v-model="allBranchOfficePrescripction" class="pl-5 pt-2" label="Todas las sucursales"></v-switch>-->
            <validation-observer ref="observer3">
              <form @submit.prevent="excelMedicalPrescriptionIntrahospital()">
                <div class="col-md-12 text-center">
                  <DateByRangeComponent
                    ref="DateByRangeComponent"
                    :typeCalendar="3"
                    v-on:dateChange="dateChange"
                  />
                </div>
                <!--<v-switch  v-model="isFormat" class="pl-5 pt-2" label="Documento con formato de firma"></v-switch>-->
                <div class="col-md-12 text-center">
                  <button
                    class="btn btn-outline-success mr-4 mt-4"
                    type="submit"
                  >
                    <i class="feather icon-download mr-1"></i>
                    {{ $t("global.generate") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger mt-4"
                    @click="clearDateAndCloseDialog(3)"
                  >
                    {{ $t("global.cancel") }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="medicalPrescriptionDialog" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">
            <h4>
              {{ $t("traceability.dispensing_by_date") }}
            </h4>
          </v-card-title>
          <v-container class="pt-0">
            <!--<v-switch  v-model="allBranchOfficePrescripction" class="pl-5 pt-2" label="Todas las sucursales"></v-switch>-->
            <validation-observer ref="observer3">
              <form @submit.prevent="excelMedicalPrescription()">
                <div class="col-md-12 text-center">
                  <DateByRangeComponent
                    ref="DateByRangeComponent"
                    :typeCalendar="3"
                    v-on:dateChange="dateChange"
                  />
                </div>
                <!--<v-switch  v-model="isFormat" class="pl-5 pt-2" label="Documento con formato de firma"></v-switch>-->
                <div class="col-md-12 text-center">
                  <button
                    class="btn btn-outline-success mr-4 mt-4"
                    type="submit"
                  >
                    <i class="feather icon-download mr-1"></i>
                    {{ $t("global.generate") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger mt-4"
                    @click="clearDateAndCloseDialog(2)"
                  >
                    {{ $t("global.cancel") }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Modal de rango de fecha -->

      <!-------------------------------------------------------- Salidas --------------------------------------------------------->
      <v-dialog v-model="dialogOriginDestiny" max-width="550" persistent>
        <v-card>
          <v-card-title class="headline pb-5">
            <h4>{{ $t(modalOriginDestinyTitle) }}</h4>
          </v-card-title>
          <div class="col-12 pt-0 pl-6 pr-6 pb-8">
            <v-tabs
              class="br"
              v-model="tab"
              background-color="#263A5B"
              centered
              dark
              icons-and-text
            >
              <v-tab href="#1">
                POR FACTURA
                <v-icon>feather icon-folder</v-icon>
              </v-tab>
              <v-tab href="#2">
                POR SUCURSAL
                <v-icon>feather icon-truck</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="1" eager>
                <v-container v-if="tab == 1" class="pt-6 p-0">
                  <validation-observer>
                    <div class="col-md-12 pt-0">
                      <form @submit.prevent="modalOriginDestinySubmit">
                        <div class="text-center" v-if="levelOriginDestiny == 1">
                          <v-autocomplete
                            class="br"
                            v-model="listInvoceSelect"
                            :items="listInvoce"
                            clearable
                            :no-data-text="$t('label.no_results')"
                            label="Factura"
                          ></v-autocomplete>
                        </div>
                        <div class="col-md-12 text-center">
                          <button
                            class="btn btn-outline-success mr-4 mt-4"
                            type="submit"
                          >
                            <i class="feather icon-download mr-1"></i>
                            {{ $t("global.generate") }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-danger mt-4"
                            @click="dialogOriginDestiny = false"
                          >
                            {{ $t("global.cancel") }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </validation-observer>
                </v-container>
              </v-tab-item>
              <v-tab-item value="2" eager>
                <v-container v-if="tab == 2" class="pt-6 p-0">
                  <validation-observer>
                    <div class="col-md-12 pt-0 pb-8">
                      <form @submit.prevent="modalOriginDestinySubmit">
                        <div class="text-center" v-if="levelOriginDestiny == 1">
                          <AllBranchOfficeComponent
                            :branchLabel="$t('label.branch_origin')"
                            v-on:branchChange="branchChangeOrigin"
                          />
                          <hr />
                        </div>
                        <div class="row text-center">
                          <div
                            :class="
                              levelOriginDestiny == 1 ? 'col-md-9' : 'col-md-11'
                            "
                          >
                            <AllBranchOfficeComponent
                              ref="allBranchOfficeDestiny"
                              :branchLabel="$t('label.branch_destination')"
                              v-on:branchChange="branchChangeDestiny"
                            />
                          </div>

                          <div class="col-md-2" v-if="levelOriginDestiny == 1">
                            <v-switch
                              v-model="isAll"
                              inset
                              @change="allBranchOfficeByDestiny()"
                              class="pl-5 pt-2"
                              :label="$t('label.all')"
                            ></v-switch>
                          </div>
                        </div>

                        <div
                          class="text-center"
                          v-if="
                            levelOriginDestiny == 1 || levelOriginDestiny == 3
                          "
                        >
                          <hr/>
                          <DateByRangeComponent
                          class="pb-4"
                            :typeCalendar="1"
                            v-on:dateChange="dateChange"
                          />
                        </div>

                        <div
                          class="text-center pb-10"
                          v-if="levelOriginDestiny == 1"
                        >
                          <hr />
                          <v-autocomplete
                          class="pb-2"

                            :label="$t('label.output_types')"
                            :items="typeOrderList"
                            v-model="typeOrder"
                          />
                        </div>
                        <div class="col-md-12 text-center">
                          <button
                            class="btn btn-outline-success mr-4 mt-4"
                            type="submit"
                          >
                            <i class="feather icon-download mr-1"></i>
                            {{ $t("global.generate") }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-danger mt-4"
                            @click="dialogOriginDestiny = false"
                          >
                            {{ $t("global.cancel") }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </validation-observer>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogReportEntries" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">
            <h4>Reporte de Entradas y Salidas</h4>
          </v-card-title>
          <v-container class="pt-0">
            <form @submit.prevent="reportEntriesAndOutputs">
              <DateByRangeComponent
                :typeCalendar="2"
                v-on:dateChange="dateChange"
              />

              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-outline-success ml-3">
                  <i class="feather icon-download mr-1"></i> Generar
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger ml-3"
                  @click="dialogReportEntries = false"
                >
                  <i class="feather icon-x mr-1"></i> Cancelar
                </button>
              </div>
            </form>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Almacen stock observacion -->
      <v-dialog v-model="dialogStockObservation" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">
            <h4>{{ $t("traceability.warehouse_title") }}</h4>
          </v-card-title>
          <v-container class="pt-0">
            <form @submit.prevent="traceabilityStockObservation">
              <DateByRangeComponent
                :typeCalendar="2"
                v-on:dateChange="dateChange"
                :weekdaysArray="[]"
              />

              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-outline-success ml-3">
                  <i class="feather icon-download mr-1"></i> Generar
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger ml-3"
                  @click="dialogStockObservation = false"
                >
                  <i class="feather icon-x mr-1"></i> Cancelar
                </button>
              </div>
            </form>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    <!-- End Rightbar -->

    <!--------------------------------------------------------- Kardex --------------------------------------------------------->

    <!-- Almacen stock observacion -->
  </div>

  <!-- End Containerbar -->
  <!-- Start js -->
</template>

<script>
import { UtilFront, ServicePath, PermissionConstants } from "@/core";
import { ExcelRequest, RemissionRequest } from "@/core/request";
import SelectBranchOffice from "@/common/select/SelectBranchOffice.vue";

import AllBranchOfficeComponent from "@/common/select/AllBranchOfficeComponent.vue";
import AllSuppliersComponent from "@/common/select/AllSuppliersComponent.vue";
import DateByRangeComponent from "@/common/calendar/DateByRangeComponent.vue";
import ReportContract from "@/views/traceability/ReportContract.vue";
import ReportOpd from "@/views/traceability/ReportOpd.vue";
import { mapState, mapMutations } from "vuex";

import axios from "axios";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "traceability.traceability",
      sending: false,
      dialogReportEntries: false,
      idProduct: 0,
      products: [],
      dialog: false,
      dialogExistence: false,
      idsProduct: [],
      lots: [],
      lotsList: [],
      loading: null,
      dialogReport: false,
      permissions: PermissionConstants,
      levelCalendar: 0,
      dialogDateRange: false,
      levelOriginDestiny: 0,
      dialogOriginDestiny: 0,
      branchOfficeSelect: "",
      idUser: "",
      typeOrderList: [],
      typeOrder: 1,

      /* Dispensacion */
      dateMov: "",
      dialogPrescription: false,
      dateModelMov: {
        startDate: "",
        endDate: "",
      },
      isFormat: false,
      allBranchOfficePrescripction: false,
      medicalPrescriptionDialog: false,

      medicalPrescriptionIntrahospitalDialog: false,
      /* Productos en movimiento */
      dialogProductsMovent: false,
      allBranchOffice: [],
      allIdBranchOffice: 0,

      /* Salidas */
      isAll: false,
      idBranchOfficeOrigin: 0,
      idBranchOfficeDestiny: 0,
      dateShipment: {},
      /* Recepcion */

      /* Almacen  */
      dialogStockObservation: false,
      idSupplier: 1,

      /*Entradas */
      isAllSupplier: false,
      tab: 0,
      listInvoce: [],
      listInvoceSelect: null,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectBranchOffice,
    AllBranchOfficeComponent,
    AllSuppliersComponent,
    DateByRangeComponent,
    ReportContract,
    ReportOpd,
  },

  created() {
    this.updateAction(1);
    let infoUser = UtilFront.getDataUser();
    this.idUser = infoUser.idUser;
    this.updateOrigin(this.branchOfficeState.idBranchoffice);
    this.typeOrderList = [
      {
        text: "Salida general",
        value: 1,
      },
      {
        text: "Salida con motivo",
        value: 2,
      },
    ];
    this.listAllProducts();
    this.listAllInvoce();
  },
  watch: {
    // En caso de que cambie la sucursal actualiza la tabla
    tab() {
      if (this.tab) this.listInvoceSelect = null;
    },
    branchOfficeState() {
      this.updateOrigin(this.branchOfficeState.idBranchoffice);
    },
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      return "traceability.traceability";
    },
    isMoreThanTwoBranchOffices() {
      return this.branchOffices.length > 1;
    },

    /* Modal Rango de Fecha */
    modalDateRangeTitle() {
      switch (this.levelCalendar) {
        case 1:
          return "traceability.dispensing_by_date";
        case 2:
          return "traceability.inputs_per_supplier";
        case 3:
          return "traceability.tickets_by_cost";
        case 4:
          return "Entradas por trapaso";
        default:
          return "traceability.not_defined";
      }
    },
    modalDateRangeSubmit() {
      switch (this.levelCalendar) {
        case 1:
          return excelMedicalPrescription;
        case 2:
          return traceabilityRemmisionSupplier;
        case 3:
          return excelCostsPerWarehouse;
        default:
          return "No definido";
      }
    },
    modalDateRangeCalendar() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return "";
      }

      this.dateModelMov.startDate = moment(this.dateMov.start).format(
        "YYYY-MM-DD"
      );
      this.dateModelMov.endDate = moment(this.dateMov.end).format("YYYY-MM-DD");

      return `${moment(this.dateMov.start).format("YYYY-MM-DD")} al ${moment(
        this.dateMov.end
      ).format("YYYY-MM-DD")}`;
    },
    modalDateRangeCalendarValid() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return false;
      }
      //this.filter();
      return true;
    },
    /* Modal Rango de Fecha */

    /* Modal de Origen y destino */
    modalOriginDestinyTitle() {
      switch (this.levelOriginDestiny) {
        case 1:
          return "traceability.outputs";
          break;
        case 2:
          return "traceability.reception";
          break;
        case 3:
          return "traceability.entries";
          break;
        default:
          return "traceability.not_defined";
      }
    },
  },
  methods: {
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION", // Funcion para cambiar el valor del action global
    }),
    /* Global */
    clearDateAndCloseDialog(dialog) {
      this.dateModelMov.startDate = "";
      this.dateModelMov.endDate = "";
      this.$refs.DateByRangeComponent.modalDateRangeClean();
      if (dialog == 1) {
        this.dialogDateRange = false;
      } else if (dialog == 2) {
        this.medicalPrescriptionDialog = false;
      } else if (dialog == 3) {
        this.medicalPrescriptionIntrahospitalDialog = false;
      }
    },
    modalOriginDestinySubmit() {
      switch (this.levelOriginDestiny) {
        case 1:
          return this.excelShipments();
        case 2:
          return this.excelReception();
        default:
          return "No definido";
      }
    },
    updateOrigin(item) {
      this.allIdBranchOffice = item;
    },
    async downloadDispensingConsolidated(payload) {
      try {
        this.loading = UtilFront.getLoadding();
        let excelRequest;
        if (payload) {
          excelRequest = ExcelRequest.dispensingConsolidated();
        } else {
          excelRequest = ExcelRequest.dispensingConsolidatedNegate();
        }
        const { data } = await excelRequest;
        await ExcelRequest.downloadBase64(
          data.data.data,
          payload
            ? "consumo_consolidado.xlsx"
            : "consumo_consolidado_negado.xlsx"
        );
        if (payload) {
          this.$toast.success("Reporte generado (consumo_consolidado.xlsx)");
        } else {
          this.$toast.success(
            "Reporte generado (consumo_consolidado_negado.xlsx)"
          );
        }
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        this.loading.hide();
      }
    },
    openDialogMedicalPrescription(level) {
      this.medicalPrescriptionDialog = true;
      this.levelCalendar = level;
    },
    openDialogMedicalIntrahospitalPrescription(level) {
      this.medicalPrescriptionIntrahospitalDialog = true;
      this.levelCalendar = level;
    },
    changeLevel(level) {
      this.dialogDateRange = true;
      this.levelCalendar = level;
    },
    changeLevelOriginDestiny(level) {
      this.dialogOriginDestiny = true;
      this.levelOriginDestiny = level;
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    currentBranchOffice() {
      let branchOffice = this.branchOffices.filter((element) => {
        if (element.value.idBranchoffice == this.branchOffice.idBranchoffice)
          return element;
      });

      this.branchOfficeSelect = branchOffice[0].text;
    },
    /* Global */

    /* Modal por rango de fechas */
    modalDateRangeClean() {
      this.dateMov = "";
      this.dateModelMov.startDate = "";
      this.dateModelMov.endDate = "";
    },
    /* Modal por rango de fechas */

    /*--------------------------------------------Entradas--------------------------------------------*/
    traceabilityRemmisionSupplier() {
      //Verifico que contenga el rango de fechas
      if (
        this.dateModelMov.startDate != "" &&
        this.dateModelMov.endDate != ""
      ) {
        //Muestro la pantalla de carga
        if (this.levelCalendar == 4) {
          this.loading = UtilFront.getLoadding();
          ExcelRequest.reportRemissionSuplierTransfer({
            userBranchOffice: {
              idBranchOffice: this.allIdBranchOffice,
              idUser: this.idUser,
            },
            startEndDate: {
              startDate: this.dateModelMov.startDate,
              endDate: this.dateModelMov.endDate,
            },
          })
            .then((response) => {
              let { success, message, data } = response.data.data;
              if (success) {
                ExcelRequest.downloadBase64(data, message);
                this.$toast.success("Reporte generado (documento.xlsx)");
              } else {
                this.$toast.error(message);
              }
            })
            .finally(() => {
              this.loading.hide();
              this.dialogMovEx = false;
            });
        } else {
          this.loading = UtilFront.getLoadding();
          ExcelRequest.reportRemissionSuplier({
            userBranchOffice: {
              idBranchOffice: this.allIdBranchOffice,
              idUser: this.idUser,
            },
            startEndDate: {
              startDate: this.dateModelMov.startDate,
              endDate: this.dateModelMov.endDate,
            },
          })
            .then((response) => {
              let { success, message, data } = response.data.data;
              if (success) {
                ExcelRequest.downloadBase64(data, message);
                this.$toast.success("Reporte generado (documento.xlsx)");
              } else {
                this.$toast.error(message);
              }
            })
            .finally(() => {
              this.loading.hide();
              this.dialogMovEx = false;
            });
        }
      }
    },

    excelCostsPerWarehouse() {
      if (
        this.dateModelMov.startDate != "" &&
        this.dateModelMov.endDate != ""
      ) {
        //Muestro la pantalla de carga
        this.loading = UtilFront.getLoadding();
        ExcelRequest.reportCostsPerWarehouse({
          idBranchOffice: this.allIdBranchOffice,
          startDate: this.dateModelMov.startDate,
          endDate: this.dateModelMov.endDate,
        })
          .then((response) => {
            ExcelRequest.download(response, "entradas_por_costo.xlsx");
            this.$toast.success("Reporte generado (documento.xlsx)");
          })
          .finally(() => {
            this.loading.hide();
          });

        this.dialogMovEx = false;
        //this.clearCalendarMov();
      }
    },
    /*---------------------------------------------Almacen--------------------------------------------*/
    traceabilityStockObservation() {
      this.loading = UtilFront.getLoadding();

      ExcelRequest.reportStockObservation({
        startDate: this.dateShipment.startDate,
        endDate: this.dateShipment.startDate,
      })
        .then((response) => {
          ExcelRequest.download(response, "inventario_ciclico.xlsx");
          this.$toast.success("Reporte generado (inventario_ciclico.xlsx)");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    reportEntriesAndOutputs() {
      this.loading = UtilFront.getLoadding();
      ExcelRequest.reportCheckoutAndCheckIn({
        checkinDate: this.dateShipment.startDate,
        idUser: this.idUser,
        idBranchOffice: this.allIdBranchOffice,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            ExcelRequest.download(data, message);
            this.$toast.success("Reporte generado (documento.xlsx)");
          } else {
            this.$toast.error(message);
          }
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    traceabilityExpiredStock() {
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();

      ExcelRequest.reportExpiredStock({
        userId: UtilFront.getIdUser(),
        branchOfficeId: this.allIdBranchOffice,
        allBranchOfffice: false,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;

          if (success) {
            ExcelRequest.downloadBase64(data, message);
            this.$toast.success("Reporte generado (documento.xlsx)");
          } else {
            this.$toast.error(message);
          }
        })
        .finally(() => {
          this.loading.hide();
        });
    },

    /*---------------------------------------------Almacen--------------------------------------------*/

    /* Dispensacion */
    excelMedicalPrescription() {
      if (
        this.dateModelMov.startDate != "" &&
        this.dateModelMov.endDate != ""
      ) {
        //Muestro la pantalla de carga
        this.loading = UtilFront.getLoadding();
        ExcelRequest.reportMedicalPrescriptionPercentage({
          idBranchOffice: this.allIdBranchOffice,
          startDate: this.dateModelMov.startDate + " 00:00:00",
          endDate: this.dateModelMov.endDate + " 23:59:59",
        })
          .then((response) => {
            ExcelRequest.download64(response, "dispensacion_y_negados.xlsx");
            this.$toast.success("Reporte generado (documento.xlsx)");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading.hide();
          });
      }
    },
    excelMedicalPrescriptionIntrahospital() {
      if (
        this.dateModelMov.startDate != "" &&
        this.dateModelMov.endDate != ""
      ) {
        //Muestro la pantalla de carga
        this.loading = UtilFront.getLoadding();

        ExcelRequest.reportMedicalPrescriptionIntrahospitalByDate({
          idBranchOffice: this.allIdBranchOffice,
          level: this.allBranchOfficePrescripction ? 1 : 2,
          //idUser: 0,
          startDate: this.dateModelMov.startDate,
          endDate: this.dateModelMov.endDate,
          format: this.isFormat,
        })
          .then((response) => {
            let { success, message, data } = response.data.data;

            if (success) {
              ExcelRequest.downloadBase64(data, message);
              this.$toast.success("Reporte generado (documento.xlsx)");
            } else {
              this.$toast.error(message);
            }
          })
          .finally(() => {
            this.loading.hide();
          });
      }
    },
    /* Productos en movimiento */
    excelMovingProducts() {
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();
      ExcelRequest.reportInventoryInMovement({
        idBranchOffice: this.allIdBranchOffice,
        startDate: 0,
        endDate: 0,
      })
        .then((response) => {
          ExcelRequest.download(response, "inventario_en_cedis.xlsx");
          this.$toast.success("Reporte generado (inventario_en_cedis.xlsx)");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    excelRemissionReception() {
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();

      ExcelRequest.reportRemissionRequest({
        idBranchOffice: this.allIdBranchOffice,
      })
        .then((response) => {
          ExcelRequest.download(response, "EntradasRecepciones.xlsx");
          this.$toast.success("Reporte generado (documento.xlsx)");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /* Productos en movimiento */

    /* Salidas */
    allBranchOfficeByDestiny() {
      this.$refs.allBranchOfficeDestiny.reloadComponentDisabled(this.isAll);
    },
    allSuppliersListDisable() {
      this.$refs.allSuppliersList.reloadComponentDisabled(this.isAllSupplier);
    },
    branchChangeOrigin(item) {
      this.idBranchOfficeOrigin = item.idBranchOffice;
    },
    idSuplierChange(item) {
      this.idSupplier = item.idSupplier;
    },
    branchChangeDestiny(item) {
      this.idBranchOfficeDestiny = item.idBranchOffice;
    },
    dateChange(dateShipment) {
      this.dateShipment = dateShipment;
      this.dateModelMov = dateShipment;
    },
    excelShipments() {
      if (this.tab == 1) {
        this.loading = UtilFront.getLoadding();
        ExcelRequest.reportShipmentAndProduct({
          invoice: this.listInvoceSelect,
        })
          .then((response) => {
            ExcelRequest.download(response, "ordenes_de_salida.xlsx");
            this.$toast.success("Reporte generado (documento.xlsx)");
          })
          .finally(() => {
            this.loading.hide();
          });
      } else {
        if (
          this.idBranchOfficeOrigin != this.idBranchOfficeDestiny ||
          this.isAll
        ) {
          if (
            (this.idBranchOfficeOrigin != "" &&
              this.idBranchOfficeDestiny != "") ||
            this.isAll
          ) {
            //Muestro la pantalla de carga
            this.loading = UtilFront.getLoadding();
            ExcelRequest.reportShipmentAndProduct({
              idBranchOfficeOrigin: this.idBranchOfficeOrigin,
              idBranchOfficeDestiny: this.idBranchOfficeDestiny,
              isAll: this.isAll,
              startEndDate: this.dateShipment,
              type: this.typeOrder,
            })
              .then((response) => {
                ExcelRequest.download(response, "ordenes_de_salida.xlsx");
                this.$toast.success("Reporte generado (documento.xlsx)");
              })
              .finally(() => {
                this.loading.hide();
              });
            this.dialogMovEx = false;
            //this.clearCalendarMov();
          }
        }
      }
    },
    excelReception() {
      if (this.idBranchOfficeDestiny != "") {
        this.loading = UtilFront.getLoadding();
        ExcelRequest.reportReception({
          idBranchOfficeDestiny: this.idBranchOfficeDestiny,
        })
          .then((response) => {
            ExcelRequest.download(response, "recepcion.xlsx");
            this.$toast.success("Reporte generado (documento.xlsx)");
          })
          .finally(() => {
            this.loading.hide();
          });
      }
    },
    messageQuantity(item) {
      let result = item.quantityReceived - item.quantity;
      if (result == 0) {
        return "";
      } else if (result < 0) {
        return `${result * -1} faltantes`;
      } else if (item.quantityReceived > item.quantity) {
        return `${result} sobrantes`;
      }
    },
    traceabilityKardex() {
      if (this.idProduct == null || this.idProduct == 0) {
        this.$toast.error("Seleccione un producto");
        return;
      }
      this.loading = UtilFront.getLoadding();

      // this.$toast.success("Generando documento excel");
      this.sending = false;
      this.dialog = false;

      ExcelRequest.reportTraceabilityProduct({
        idBranchOffice: this.allIdBranchOffice,
        idProduct: this.idProduct,
      })
        .then((response) => {
          if (response.data.size > 0) {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Kardex_producto.xlsx");
            document.body.appendChild(link);
            link.click();
          } else {
            this.$toast.warning("El producto no cuenta con existencias");
          }
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDFExistence() {
      let lotsString = ",";

      if (this.idsProduct == "" || this.idsProduct == "null") return;

      if (this.lotsList != null && this.lotsList.length > 0) {
        lotsString = this.lotsList
          .map((obj) => {
            return `${obj}`;
          })
          .join(",");
      }

      let params = `lots=${lotsString}&idProducts=${this.idsProduct}`;

      this.$toast.success("Generando documento PDF");
      this.loading = UtilFront.getLoadding();

      ExcelRequest.downloadPDFExistence(params)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          window.open(url);
        })
        .finally(() => {
          this.loading.hide();
          this.sending = false;
          this.lotsList = [];
          this.dialogExistence = false;
        });
    },
    async listAllProducts() {
      try {
        const response = await axios.post(ServicePath.GET_ALL_PRODUCTS);
        const data = response.data.data.data;
        const products = data.map((obj) => ({
          text: `${obj.productKey} | ${obj.description} | Presentación: ${obj.unitMeasurementDescription}`,
          value: obj.idProduct,
        }));
        this.products = products;
      } catch (error) {
        console.error(error);
      }
    },
    listAllLot(item) {
      this.lots = [];
      axios
        .post(ServicePath.GET_ALL_LOTS, { idProduct: item })
        .then((response) => {
          const { data } = response.data.data;
          this.lots = data;
        });
    },
    async listAllInvoce() {
      RemissionRequest.getListInvoice()
        .then((response) => {
          let { data } = response;
          this.listInvoce = data.map((item) => {
            const invoiceValue = item.invoice;
            return { value: invoiceValue, text: invoiceValue };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    excel() {
      this.$toast.success("Reporte generado (documento.xlsx)");
      ExcelRequest.reportReceptionHistory({
        id: this.branchOffice.idBranchoffice,
      });
    },
    pdf(item) {
      let send = "idReception=" + item.idReception;
      ExcelRequest.reportReceptionPdf(send);
    },

    openDialog() {
      this.dialogExistence = true;
      //this.listAllLot();
    },
    excelDispensingPercentage() {
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();

      ExcelRequest.reportMedicalPrescriptionPercentage({
        idBranchOffice: this.allIdBranchOffice,
      })
        .then((response) => {
          ExcelRequest.download(response, "dispensación_y_negados.xlsx");
          this.$toast.success("Reporte generado (dispensación_y_negados.xlsx)");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadDocument(url) {
      window.open(`/files/${url}`, "_blank");
    },
  },
};
</script>

<style scoped>
.theme--light.v-messages {
  color: rgb(255 0 0);
}

/*Código para modificar los botones */
@media (max-width: 576px) {
  .controlSize {
    text-align: center;
  }

  /*Código para modificar las barras de fecha o botones de busqueda*/
  .selectSize {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .controlSize {
    float: right;
  }
}

.list-group-item {
  border: 0px solid !important;
  padding: 0.5rem 2rem !important;
}

.excel-icon {
  color: #429559 !important;
}
</style>
